import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import tw, { css, styled } from 'twin.macro'

import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../components/utils/filter-locale'
import { onPromoClick } from '../components/utils/analytics'
import DolphinLogo from './svg/dolphin'
import MineralSwimLogo from './svg/mineralSwim'
import DolphinWaveLogo from './svg/wave'
import EcoSwim from './svg/eco-swim'
import OzoneSwim from './svg/ozoneSwim'
import EcoClear from './svg/ecoClearLogo'
import { Triangle } from 'react-feather'
import localeCheck from './utils/locale-check'
/** Styled by Tailwindcss & Emotion */
const Section = tw.section`flex flex-col w-full justify-center items-center py-8 md:py-16 bg-gray-200`
const Title = tw.h2`font-display font-extrabold text-4xl text-center mb-2 text-blue-500`
const Subtitle = tw.h3`font-sans text-gray-600 text-2xl text-center px-4 mb-4 md:px-8 lg:mb-20`
const Items = styled.div`
	${tw`flex flex-col md:flex-row m-auto md:m-0 w-11/12 lg:w-11/12 xl:w-4/5 justify-center flex-wrap`}

	@media (max-width:767px) {
		${tw`items-center`}
	}
`
const Brand = tw.figure`bg-transparent py-1 px-2 w-full flex justify-center items-center mb-6`
const Item = styled.div`
	${tw`relative flex flex-col px-4 items-center w-full md:w-6/12 lg:w-1/3 mb-2 mb-8 max-w-xl`}
	transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	overflow: hidden;
	.item-image {
		${tw`bg-gray-500`}
		transition: opacity 0.3s ease-in-out;
		width: 100%;
		height: auto;
		z-index: 0;
		img,
		picture {
			opacity: 0.875;
		}
	}
	div[role='link'] {
		${tw`mt-auto cursor-pointer`}
		button:hover {
			transition: background-color 0.3s ease-in-out;
		}
	}
	&:hover {
		.item-image {
			opacity: 0.875;
		}
	}
	@media (max-width: 767px) {
		.item-image {
			/* display: none; */
		}
		figure {
			${tw`relative py-2`}
		}
	}
`
const Content = styled.div`
	${tw`flex flex-col flex-1 w-full pt-8 rounded-b items-center`}

	> p:last-of-type {
		${tw`pl-0 pr-6 mt-10 text-lg`}
		color: #666666;
	}
`
const ItemSubTitle = tw.h4`font-display font-bold text-2xl text-left w-full leading-tight mb-2 mb-4 mx-auto mb-6`
const Paragraph = styled.p`
	${tw`font-display relative pl-4`}
`
const Bold = tw.b`font-bold`
const Italic = tw.i`italic`
const UnorderedList = styled.ul`
	${tw`h-full flex flex-col justify-end w-full`}

	> li > p:before {
		position: absolute;
		content: '';
		height: 6px;
		width: 6px;
		left: 0;
		top: 5px;
		background: #58c8df;
		border-radius: 100%;
	}
`
const ItemImage = styled.img`
	max-width: 100px;
	${tw`mb-2`}
`
const ItemImageHolder = styled.div`
	${tw`w-full relative`}
	> div[role="link"]:not(:first-of-type) {
		@media (min-width: 525px) {
			${tw`text-xl`}
		}
		transition: 0.3s ease-in-out;
		background-color: rgba(34, 41, 47, 0.3);
		${tw`text-white text-lg absolute flex bottom-0 w-full py-6 text-center justify-center items-center tracking-widest capitalize hover:bg-black`}
	}
`
const TriangleSvg = styled(Triangle)`
	${tw`ml-4`}
	transform: rotate(90deg);
`
const MineralSwimSvg = styled(MineralSwimLogo)`
	${tw`pt-1 pb-2 px-2 rounded md:bg-transparent md:pt-0 md:pb-0 md:px-0 md:rounded-none`}
`
const EcoSwimSvg = styled(EcoSwim)`
	${tw`md:bg-transparent rounded md:rounded-none pt-1 pb-2 px-2 md:pt-0 md:pb-0 md:px-0`}
`
const OzoneSwimSvg = styled(OzoneSwim)`
	${tw`md:bg-transparent rounded md:rounded-none pt-1 pb-2 px-2 md:pt-0 md:pb-0 md:px-0`}
`
const EcoClearSvg = styled(EcoClear)`
	${tw`md:bg-transparent rounded md:rounded-none pt-1 pb-2 px-6 md:px-2 md:pt-0 md:pb-0 md:px-0`}
`

const isBrowser = typeof window !== 'undefined'
/**
 * Lists featured brand products/services
 * TODO add domain and locale props
 */
const Featured = ({ locale }) => {
	const { featured } = useStaticQuery(graphql`
		query getFeatured {
			featured: allContentfulFeaturedItems(
				filter: { contentful_id: { eq: "2ihBtuqfdv3KT8UzghmW5Q" } }
			) {
				edges {
					node {
						node_locale
						title
						subtitle
						items {
							name
							body {
								json
							}
							url
							image {
								title
								description
								fluid(
									maxWidth: 400
									maxHeight: 400
									quality: 80
									cropFocus: TOP
								) {
									...GatsbyContentfulFluid
								}
							}
						}
					}
				}
			}
		}
	`)
	/** Filter by locale */
	const [
		{
			node: { title, subtitle, items },
		},
	] = filterLocale(featured.edges, locale)
	/** Render "Rich Text" from Contentful to the DOM */
	const options = {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: node => {
				const { file, description } = node.data.target.fields
				return <ItemImage src={file[locale].url} alt={description[locale]} />
			},
			[BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
			[BLOCKS.HEADING_4]: (node, children) => (
				<ItemSubTitle>{children}</ItemSubTitle>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<UnorderedList>{children}</UnorderedList>
			),
		},
	}
	/** Promotion array */
	const promotions = items.map((promo, i) => ({
		id: `${String(promo.name).toUpperCase()}_${i + 1}`,
		name: promo.name,
		creative: promo.image.description,
		position: `slot${i + 1}`,
	}))
	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring promotion impressions onLoad
		 */
		if (isBrowser && window.dataLayer) {
			window.dataLayer.push({
				event: 'promotionView',
				ecommerce: {
					promoView: {
						promotions,
					},
				},
			})
		}
	}, [promotions])
	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(locale, defaultLocale)

	return (
		<Section>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			<Items>
				{items.map((item, index) => {
					return (
						<Item key={index}>
							<ItemImageHolder>
								<div
									tabIndex="-1"
									role="link"
									onClick={() => {
										onPromoClick(
											promotions[index],
											navigate(`${checkedLocale}/` + item.url + "/")
										)
									}}
									onKeyDown={() => {
										onPromoClick(
											promotions[index],
											navigate(`${checkedLocale}/` + item.url + "/")
										)
									}}
								>
									<Img
										fluid={item.image.fluid}
										alt={item.image.description}
										className="item-image"
									/>
								</div>
								<div
									tabIndex="-1"
									role="link"
									onClick={() => {
										onPromoClick(
											promotions[index],
											navigate(`${checkedLocale}/` + item.url + "/")
										)
									}}
									onKeyDown={() => {
										onPromoClick(
											promotions[index],
											navigate(`${checkedLocale}/` + item.url + "/")
										)
									}}
								>
									Learn More
									<TriangleSvg
										color="#fff"
										fill="#fff"
										width="15"
										height="15"
									/>
								</div>
							</ItemImageHolder>
							<Content>
								<Brand>
									{item.name === 'Dolphin' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/dolphin/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/dolphin/`)
												)
											}}
										>
											<DolphinLogo width="150" height="70" color={'#353E47'} />
										</div>
									)}
									{item.name === 'Mineral Swim' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/mineral-swim/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/mineral-swim/`)
												)
											}}
										>
											<MineralSwimSvg width="280" height="60" />
										</div>
									)}
									{item.name === 'Dolphin Wave' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/dolphin/commercial/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/dolphin/commercial/`)
												)
											}}
										>
											<DolphinWaveLogo
												width="170"
												height="60"
												color={'#353E47'}
											/>
										</div>
									)}
									{item.name === 'Ozone Swim' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/ozone-swim/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/ozone-swim/`)
												)
											}}
										>
											<OzoneSwimSvg width="250" height="60" color={'#353E47'} />
										</div>
									)}
									{item.name === 'Ecoswim' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/eco-swim/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/eco-swim/`)
												)
											}}
										>
											<EcoSwimSvg width="250" height="60" color={'#353E47'} />
										</div>
									)}
									{item.name === 'Ecoclear' && (
										<div
											tabIndex="-1"
											role="link"
											onClick={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/eco-clear/`)
												)
											}}
											onKeyDown={() => {
												onPromoClick(
													promotions[index],
													navigate(`${checkedLocale}/eco-clear/`)
												)
											}}
										>
											<EcoClearSvg
												width="250"
												height="60"
												color={'#353E47'}
												secondary="#57c8de"
											/>
										</div>
									)}
								</Brand>
								{item.body
									? documentToReactComponents(item.body.json, options)
									: ''}
							</Content>
						</Item>
					)
				})}
			</Items>
		</Section>
	)
}

export default Featured
